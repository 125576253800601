import { inject, Injectable } from '@angular/core';
import { TeamRosterItem } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthTeamModel, GthTeamPlayerModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { lastValueFrom, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TeamRosterService extends FirestoreService<TeamRosterItem> {
  private usersService = inject(UserService);

  protected basePath = 'team_roster';

  async removePlayer(id: string) {
    const doc = await lastValueFrom(this.doc(id).get());

    if (!doc || !doc.exists) return false;

    try {
      await this.delete(id);

      return true;
    } catch {
      return false;
    }
  }

  async removePlayerByUserId(teamId: string, userId: string) {
    const playerRoles = await this.firestore
      .collection(this.basePath, (ref) =>
        ref.where('userId', '==', userId).where('teamId', '==', teamId),
      )
      .get()
      .pipe(first())
      .toPromise();

    const docs = playerRoles.docs;

    if (!docs || docs.length === 0) return false;

    const doc = docs[0];

    if (!doc) return false;

    const { id } = doc;

    try {
      await this.delete(id);

      return true;
    } catch {
      return false;
    }
  }

  async updateRole(id: string, role: string) {
    const doc = await lastValueFrom(this.doc(id).get());

    if (!doc || !doc.exists) {
      return false;
    }

    try {
      return await this.doc(id)
        .update({ role })
        .then(() => true)
        .catch(() => false);
    } catch {
      return false;
    }
  }

  async updateRoleByUserId(teamId: string, userId: string, role: string) {
    const querySnap = await this.firestore
      .collection(DBUtil.TeamRoster, (ref) =>
        ref.where('userId', '==', userId).where('teamId', '==', teamId),
      )
      .get()
      .pipe(first())
      .toPromise();

    if (querySnap.empty) return false;

    const doc = querySnap.docs[0];

    if (!doc) return false;

    const { id } = doc;

    try {
      return await this.doc(id)
        .update({ role })
        .then(() => true)
        .catch(() => false);
    } catch {
      return false;
    }
  }

  async addPlayer({
    teamId,
    userId,
    role,
    pendingRole,
  }: {
    teamId: string;
    userId: string;
    role: string;
    pendingRole?: string;
  }) {
    pendingRole = pendingRole ? pendingRole : null;

    const teamPartial = await this.firestore
      .collection(DBUtil.TeamRoster, (ref) =>
        ref.where('userId', '==', userId).where('teamId', '==', teamId),
      )
      .get()
      .pipe(first())
      .toPromise();

    if (teamPartial.empty) {
      const user = await this.usersService.getUserById$(userId).pipe(first()).toPromise();

      if (user) {
        const newUserRef = await this.firestore
          .collection(DBUtil.TeamRoster, (ref) =>
            ref.where('email', '==', user.email).where('teamId', '==', teamId),
          )
          .get()
          .pipe(first())
          .toPromise();

        if (!newUserRef.empty) {
          const firstDoc = newUserRef.docs[0];

          await firstDoc.ref.update({ role });

          return true;
        }
      }

      try {
        await this.collection.add({
          teamId,
          userId,
          role,
          pendingRole,
        });

        return true;
      } catch (e) {
        return false;
      }
    } else if (teamPartial.docs.length === 1) {
      try {
        const doc = teamPartial.docs[0];

        await doc.ref.update({ role });

        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  async invitePlayer({
    teamId,
    email,
    role,
    userId,
  }: {
    teamId: string;
    email: string;
    role: string;
    userId: string;
  }) {
    try {
      await this.collection.add({
        teamId,
        userId,
        email,
        role,
        unregisteredUser: true,
      });

      return true;
    } catch {
      return false;
    }
  }

  async getTeamsByUserId(userId: string): Promise<GthTeamModel[]> {
    const teamPartial = this.firestore.collection(DBUtil.TeamRoster, (ref) =>
      ref.where('userId', '==', userId),
    );

    const rosterItems = await teamPartial
      .snapshotChanges()
      .pipe(
        first(),
        map((items) => {
          return items.map((item) => {
            const doc = item.payload.doc;
            const docData = doc.data() as any;
            const data = {
              id: doc.id,
              ...docData,
            } as TeamRosterItem;
            return data;
          });
        }),
      )
      .toPromise();

    const teamsRequests = rosterItems.map(async (r) => {
      const collectionRef = this.firestore.collection(DBUtil.Team);
      const teamSnapshot = await collectionRef.doc(r.teamId).get().toPromise();
      const teamObj = teamSnapshot.data() as any;
      if (!teamObj) {
        return undefined;
      }
      teamObj.roster = await this.getUsersByTeamId(r.teamId);
      return new GthTeamModel(r.teamId, teamObj as any);
    });

    return (await Promise.all(teamsRequests)).filter((t) => t !== undefined);
  }

  async getUsersByTeamId(teamId: string): Promise<any> {
    const teamPartial = this.firestore.collection(DBUtil.TeamRoster, (ref) =>
      ref.where('teamId', '==', teamId),
    );

    const rosterItems = await lastValueFrom(
      teamPartial.snapshotChanges().pipe(
        first(),
        map((items) => {
          return items.map((item) => {
            const doc = item.payload.doc;
            const docData = doc.data() as any;
            const data = {
              id: doc.id,
              ...docData,
            } as TeamRosterItem;
            return data;
          });
        }),
      ),
    );

    const users = rosterItems.map(async (r) => {
      if (r.userId) {
        const user = await lastValueFrom(
          this.usersService.getUser$(r.userId).pipe(
            catchError(() => {
              console.error(`Error finding user id "${r.userId}" when reading team roster`);
              return of(undefined);
            }),
          ),
        );

        if (!user) {
          return undefined;
        }

        const playerItem = {
          player: user,
          role: r.role,
          pendingRole: r.pendingRole,
        };

        return new GthTeamPlayerModel(user.id, r.id, playerItem);
      }

      return new GthTeamPlayerModel('', r.id, {
        player: {
          displayName: r.email,
          email: r.email,
        },
        role: r.role,
        pendingRole: r.pendingRole,
      } as any);
    });

    const players = await Promise.all(users);
    const uniqueRoster: GthTeamPlayerModel[] = [];

    players.forEach((p) => {
      if (p) {
        const existingIndex = uniqueRoster.findIndex((r) => {
          if (!p || !r) {
            return false;
          }
          return r.email === p.email;
        });
        if (existingIndex === -1) {
          uniqueRoster.push(p);
        } else {
          const existing = uniqueRoster[existingIndex];
          if (p.id && !existing.id) {
            uniqueRoster[existingIndex] = p;
          }
        }
      }
    });

    return uniqueRoster.filter((p) => p);
  }
}
